export default [
  {
    header: "Accesses",
  },
  {
    title: "Orders",
    icon: "DollarSignIcon",
    route: "apps-orders-list",
  },
  {
    title: "Users",
    icon: "UsersIcon",
    children: [
      {
        title: "User List",
        route: "apps-users-list",
      },
    ],
  },
  {
    title: "Languages",
    icon: "FlagIcon",
    children: [
      {
        title: "Language list",
        route: "pages-languages-list",
      },
      // {
      //   title: "Add a language",
      //   route: "pages-languages-create",
      // },
    ],
  },
  {
    title: "Product",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Products List",
        route: "apps-product-list",
      },
      {
        title: "Create product",
        route: "apps-product-create",
      },
      {
        title: "Categories",
        route: "apps-categories",
      },
    ],
  },
  {
    title: "Blogs",
    icon: "BookOpenIcon",
    children: [
      {
        title: "Blogs List",
        route: "pages-posts-list",
      },
      {
        title: "Add a new blog",
        route: "pages-posts-create",
      },
      {
        title: "Categories",
        route: "pages-posts-category",
      },
      {
        title: "Comments",
        route: "pages-comments-list",
      },
    ],
  },
  // {
  //   title: "Medias",
  //   icon: "ImageIcon",
  //   children: [
  //     {
  //       title: "Library",
  //       route: "pages-library-list",
  //     },
  //     {
  //       title: "Add new media",
  //       route: "pages-library-add",
  //     },
  //   ],
  // },
  {
    title: "Static Contents",
    icon: "ColumnsIcon",
    children: [
      {
        title: "Footer",
        route: "apps-static-footer",
      },
      {
        title: "About us",
        route: "apps-static-aboutUs",
      },
      {
        title: "Contact us",
        route: "apps-static-contactUs",
      },
    ],
  },
  {
    title: "Shipments",
    icon: "Navigation2Icon",
    route: "apps-shipments",
  },
  // {
  //   title: "Forms",
  //   icon: "FileTextIcon",
  //   children: [
  //     {
  //       title: "Inbox",
  //       route: "pages-forms-inbox",
  //     },
  //     {
  //       title: "Contact/online forms",
  //       route: "pages-forms-list",
  //     },
  //     {
  //       title: "Create a new form",
  //       route: "pages-forms-create",
  //     },
  //   ],
  // },
  // {
  //   title: "Menus",
  //   icon: "EyeIcon",
  //   children: [
  //     {
  //       title: "Menu List",
  //       route: "pages-menu-list",
  //     },
  //     {
  //       title: "Add New Menu",
  //       route: "pages-menu-create",
  //     },
  //   ],
  // },
  {
    title: "SEO",
    icon: "SearchIcon",
    children: [
      // {
      //   title: "Pages SEO",
      //   route: "pages-seo-pages",
      // },
      {
        title: "Blogs SEO",
        route: "pages-seo-blogs",
      },
      // {
      //   title: "Blog Categories SEO",
      //   route: "pages-seo-blog-categories",
      // },
    ],
  },
  // {
  //   title: "Settings",
  //   icon: "SettingsIcon",
  //   children: [
      // {
      //   title: "General Settings",
      //   route: "pages-settings-general",
      // },
      // {
      //   title: "Slider Settings",
      //   route: "pages-settings-slider",
      // },
      // {
      //   title: "Awards And Trophies",
      //   route: "pages-settings-awards",
      // },
      // {
      //   title: "Licenses",
      //   route: "pages-settings-docs",
      // },
      // {
      //   title: "Homepage Videos",
      //   route: "pages-settings-videos",
      // },
  //     {
  //       title: "Google Robot",
  //       route: "pages-settings-robots",
  //     },
  //     {
  //       title: "Sitemap",
  //       route: "pages-settings-sitemap",
  //     },
  //   ],
  // },
  // {
  //   title: "Representative",
  //   icon: "FlagIcon",
  //   children: [
  //     {
  //       title: "Representative List",
  //       route: "pages-representative-list",
  //     },
  //     {
  //       title: "Add New Representative",
  //       route: "pages-representative-create",
  //     },
  //   ],
  // },
  // {
  //   title: "Email Settings",
  //   icon: "MailIcon",
  //   children: [
  //     {
  //       title: "Configured Email Setting",
  //       route: "pages-email-settings-list",
  //     },
  //   ],
  // },
];
